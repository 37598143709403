import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import HeaderSection from "../components/Technology/HeaderSection"
import PlatformSection from "../components/Technology/PlatformSection"
import ArticleSection from "../components/Technology/ArticlesSection"
import StackSection from "../components/Technology/StackSection"
import TechSection from "../components/Technology/TechSection"
import dataForStackSection from "../information/Technology/StackSection/dataForStackSection"
import dataForTechSection from "../information/Technology/TechSection/dataForTechSection"
import { mainUrl } from "../js/config"
import dataForTech from '../information/Technology/dataForTechnology';

export default function Technology() {
  const { hero, ogImage } = dataForTech;
  return (
    <section>
      <SEO
        title="Technology Expertise"
        description="The OS-System team is continuously widens the scope of our technology expertise and applications technologies we use to deliver robust solutions."
        canonical={`${mainUrl}/technology/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={150}>
        <HeaderSection data={hero} />
        <PlatformSection />
        <ArticleSection />
        <TechSection data={dataForTechSection} />
        <StackSection data={dataForStackSection} />
      </Layout>
    </section>
  )
}
