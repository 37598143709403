import {
  Angular,
  React,
  Next,
  Vue,
  Nodejs,
  Nest,
  Python,
  Php,
  Java,
  Flutter,
  ReactNative,
  Ios,
  Android,
  Aws,
  ChatGpt,
  Docker,
  Google,
} from "../../../images/Services/WebTechnologies"

import {
  Firebase,
  Kubernetes,
  MongoDB,
  MySQL,
  Symfony,
  PostgreSql,
} from "../../../images/Technology/TechSection"

const dataForTechSection = {
  title: "Our tech stack",
  description: "We combine developers' talent with the best technologies currently avaliable on the market",
  techData: {
    mobile: [Flutter, ReactNative, Ios, Android],
    frontend: [Angular, React, Next, Vue],
    backend: [Nodejs, Nest, Python, Php, Java, Symfony],
    tools: [
      Aws, ChatGpt, Google,
      MongoDB, MySQL, Firebase,
      Kubernetes, PostgreSql, Docker
    ],
  },
}

export default dataForTechSection
