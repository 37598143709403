import OgImage from "../../images/Technology/og_image.png"
import Hero from "../../images/Technology/hero.svg"

const dataForWeb = {
  ogImage: OgImage,
  service: "web",
  hero: {
    title: "Technologies We Use",
    img: Hero
  },
};

export default dataForWeb;