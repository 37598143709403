import React from "react";

import "./TechSection.scss";

const TechRow = ({ rowName, techArray }) => {
  return (
    <div className="technology-container">
      <div className="row-name">
        {rowName}:
      </div>
      <div className="technology-row">
        {techArray.map((el, index) => (
          <div key={index} className="img-container"> 
            <img src={el} alt={`Technology ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

const TechSection = ({ data }) => {
  const { title, description, techData } = data;
  return (
    <div className="technology-wrapper">
      <div className="technology-content">
        <h2>{title}</h2>
				<p>{description}</p>
				<div className="technology-list">
					{Object.entries(techData).map(([key, value]) => (
						<TechRow key={key} rowName={key} techArray={value} />
					))}
				</div>
      </div>
    </div>
  );
}

export default TechSection;