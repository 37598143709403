import React from "react"

import dataForPlatform from "../../../information/Technology/PlatformSection/dataForPlatform"

import "./PlatformSection.scss"

const PlatformsSection = () => {
  return (
    <section className="platform-section">
      <div className="platform">
          <div className="platform-text">
            <h2 className="platform-title">{dataForPlatform.title}</h2>
            <p className="platform-description">{dataForPlatform.text}</p>
          </div>
          <div className="platform-list">
            {dataForPlatform.images.map(({id, src, alt}) => (
              <div key={id} className="platform-list_item">
                <img src={src} alt={alt} />
              </div>
            ))}
          </div>
        </div>
    </section>
  )
}

export default PlatformsSection
