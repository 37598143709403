import Our from "../../../images/Technology/ArticleSection/our.svg"
import Innovate from "../../../images/Technology/ArticleSection/innovate.svg"

const dataForArticles = [
  {
    id: 1,
    header: "Our engineers",
    text:
      "Our engineers are highly skilled in the latest technologies, tools, methodologies and techniques. At the same time, we always highlight the importance of having a clear understanding of our clients' business needs. Putting this approach to practice since 11 years has provided us with the unique skill sets to implement and support complex end-to-end projects.",
    img: Our,
  },
  {
    id: 2,
    header: "To innovate is to excel",
    text:
      "This is a linchpin of our corporate strategy. We strive to stay on top of emerging technologies. We invest significant effort to motivate our engineers to think innovatively, to be aware of promising emerging technologies and to forecast revolutionary changes in the business. True innovation takes effort. But it's worth it!",
    img: Innovate,
  },
]

export default dataForArticles
