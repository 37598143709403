import React from "react"

import dataForArticles from "../../../information/Technology/ArticlesSection/dataForArticles"

import "./ArticleSection.scss"

const ArticlesSection = () => {
  return (
    <section className="stack-articles">
      <div className="article-list">
        {dataForArticles.map(({ id, header, text, img }) => (
          <div key={id} className="article-list_item">
            <div className="text-container">
              <h3>{header}</h3>
              <p>{text}</p>
            </div>
            <div className="img-container">
              <img src={img}/>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ArticlesSection
