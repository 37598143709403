import React from "react"

import "./StackSection.scss"

const StackSection = ({ data }) => {
  const { title, description, images } = data
  return (
    <section className="stack-section">
      <div className="stack-content">
        <div className="stack-text">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="stack-images">
          {images.map((image) => (<div className="img-container"><img src={image} /></div>))}
        </div>
      </div>
    </section>
  )
}

export default StackSection
