import React from "react"; 

import './HeaderSection.scss'

export default function HeaderSection({ data }) {
  return (
    <section className="hs-parent">
      <div className="hs-child">
        <div className="hs-text-container">
          <h1 className="hs-title">{data.title}</h1>
        </div>
        <div className="hs-img-container">
          <img src={data.img} alt="common image" />
        </div>
      </div>
    </section>
  );
};
