import {
  BitBucket,
  Git,
  Slack,
  Trello,
  Redmine,
} from "../../../images/Technology/StackSection"

import { Jira } from '../../../images/Services/WebTechnologies';

const dataForStackSection = {
  title: "Communication",
  description: "These tools help us make our work process effective and transparent",
  images: [ Redmine, Git, Jira, Slack, BitBucket, Trello ],
}

export default dataForStackSection
